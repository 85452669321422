import React from 'react';
import AboutUsElements from '../components/ServicesPages/AboutUsComponents';
import Footer from '../components/Footer';

const AboutUsPage = () => {

    return (
        <>                 
         <AboutUsElements />        
         <Footer />                            
        </>
    );
};

export default AboutUsPage;