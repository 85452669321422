import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./services/i18n";
import * as serviceWorker from "./serviceWorker";
import { Spinner } from 'react-loading-io';

const style = { display: "block", position: "fixed", top: "calc( 50% - ( 100px / 2) )", right: "calc( 50% - ( 100px / 2) )" };

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<Spinner size={100} color='#8ec3ce' style={style} />}>    
    <App />
    </React.Suspense>    
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();

