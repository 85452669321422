import React from 'react';
import WebElements from '../components/ServicesPages/WebComponents';
import Footer from '../components/Footer';


const WebPage = () => {
    return (
        <>
         <WebElements />
         <Footer />                            
        </>
    );
};

export default WebPage;