import React from 'react';
import Typewriter from 'typewriter-effect';
//import Video from '../../videos/video13.webm';
import Video from '../../videos/video9-1.mp4';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP } from './HeroElements';
import { useTranslation } from "react-i18next";

const HeroSection = () => {
    const { t } = useTranslation();    
    
    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg autoPlay="autoplay" playsInLine="playsinline" loop="true" muted="true">                
                <source type="video/mp4" src={Video} />
                </VideoBg>                 
            </HeroBg>
            <HeroContent>
            <HeroH1><Typewriter
          options={{
          strings: ['Opencart','Online shops','Development modules','Adaptive web design'],          
          autoStart: true,          
          loop: 1,          
          pauseFor: 7000,                                      
  }}
/></HeroH1>
            <HeroP>{t("Development and administration online shops based on CMS OpenCart, development data synchronization modules, subsystems")}</HeroP>
        </HeroContent>            
        </HeroContainer>
    );
};

export default HeroSection;