import React from 'react'
import { 
ServicesPagesContainer,
ServicesPagesH1,
ServicesPagesWrapper,
ServicesPagesCard,
ServicesPagesCardFront,
ServicesPagesCardBack,
ServicesPagesCardH2,
ServicesPagesCardP,
} from './ServicesPagesElements'
import { useTranslation } from "react-i18next"

const WebElements = () => { 

    const { t } = useTranslation();    

    return (
        <>
        <ServicesPagesContainer>                     
            <ServicesPagesH1>{t(" Web dev")}</ServicesPagesH1>                      
            <ServicesPagesWrapper>                            
                <ServicesPagesCard>
                    <ServicesPagesCardFront
                    style={{ backgroundImage: "", backgroundSize: "80%" }}>                        
                    </ServicesPagesCardFront>
                    <ServicesPagesCardBack>
                    <ServicesPagesCardH2>{t("")}</ServicesPagesCardH2>
                    <ServicesPagesCardP>{t("")}</ServicesPagesCardP>                       
                    </ServicesPagesCardBack>                   
                </ServicesPagesCard>
                <ServicesPagesCard>
                    <ServicesPagesCardFront style={{ backgroundImage: "", backgroundSize: "80%" }}/>
                    <ServicesPagesCardBack>
                    <ServicesPagesCardH2>{t("")}</ServicesPagesCardH2>
                    <ServicesPagesCardP>{t("")}</ServicesPagesCardP>                       
                    </ServicesPagesCardBack>                   
                </ServicesPagesCard>
                <ServicesPagesCard>
                    <ServicesPagesCardFront style={{ backgroundImage: "", backgroundSize: "80%" }}/>
                    <ServicesPagesCardBack>
                    <ServicesPagesCardH2>{t("")}</ServicesPagesCardH2>
                    <ServicesPagesCardP>{t("")}</ServicesPagesCardP>                       
                    </ServicesPagesCardBack>                   
                </ServicesPagesCard>                                                      
            </ServicesPagesWrapper>           
        </ServicesPagesContainer>
        </>
    )
}

export default WebElements