import React from "react";
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {
  const { i18n } = useTranslation(); 

  return (
    <div className="select">
      <select
        value={i18n.language}
        onChange={(e) =>
          i18n.changeLanguage(e.target.value)
        }
        style={{paddingLeft: "3px", fontSize: "13px"}}
      >
        <option value="en">English</option>
        <option value="uk">Українська</option>
        {/* <option value="pl">Polski</option> */}
      </select>
    </div>
  );
}

export default LanguageSwitcher;