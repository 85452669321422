import styled from 'styled-components'

export const InfoContainer = styled.div`
    color: #fff;  
    /* background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#001420e3')};*/
    background: ${({lightBg}) => (lightBg ? '#9baebc' : '#001420e3')};
    
    @media screen and (max-width: 780px) {
        padding: 50px 0 50px 0;                      
    }
    
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 70vh;
    width: 100%;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 0px;
    /* justify-content: center; */

    @media screen  and (max-width: 780px) {
        grid-template-columns: 1fr; 
        padding: 0 20px;
        height: auto;            
    } 
`;

export const InfoRow = styled.div`    
    display: grid;    
    grid-auto-columns: minmax(auto, 1fr);    
    align-items: center;
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'` )};
   

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'` )};
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 10px;
    grid-area: col1;
    
    @media screen and (max-width: 480px) {
        padding: 0 15px;
    }
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 35px;
    grid-area: col2;    
    
    @media screen and (max-width: 480px) {
        padding: 0 15px;
    }
`;

export const TextWrapper = styled.div`
    max-width: 650px;
    padding-top: 0;
    padding-bottom: 10px;          
`;

export const TopLine = styled.p`
    /* color: #01bf71; */
    color: ${({ lightText }) => (lightText ? '#8ec3ce' : '#ffffff')};
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};    

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const Subtitle = styled.p`
    margin-bottom: 35px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1.3px;
    color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
    text-align: justify;

    @media screen and (max-width: 480px) {
        letter-spacing: 0px;
    }
`;

export const BtnWrap =styled.div`
    display: flex;
    justify-content: flex-start;    
`;

export const ImgWrap = styled.div`
    display: flex;
    justify-content: center;
    /* border: 1px solid white;  */ 
 
    animation: animate 2s infinite ease alternate;
    
    @keyframes animate {
    to {      
      transform: translateY(5px);
    }
  }
`;

export const Img = styled.img`
    width: 85%;    
    margin: 0 0 0 0;    
    filter: drop-shadow(10px 10px 5px rgba(0,0,0,0.3));    
    
   
    
    @media screen and (max-width: 1080px) {
           width: 75%;
           padding-left: 0px;               
    }
`;
