import React, { useState } from 'react';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
// eslint-disable-next-line
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from '../components/InfoSection/Data';
import Navbar from '../components/Navbar';
import Services from '../components/Services';
import Sidebar from '../components/Sidebar';
import { Analytics } from '@vercel/analytics/react';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };    

    return (
        <>
         <Sidebar isOpen={isOpen} toggle={toggle} />
         <Navbar toggle={toggle} />
         <HeroSection />
         <InfoSection {...homeObjOne} />                          
         <InfoSection {...homeObjTwo} />                                                   
         <InfoSection {...homeObjThree} />                           
         {/* <InfoSection {...homeObjFour} />  */}
         <Services />
         <Footer />
         <Analytics />                            
        </>
    );
};

export default Home;
