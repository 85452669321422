import React from 'react';
import { Button } from '../ButtonElements';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2,
TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements';
import { animateScroll as scroll } from 'react-scroll';
import { useTranslation } from "react-i18next";


const InfoSection = ({lightBg, id, imgStart, topLine, lightText, 
headLine, darkText, description, buttonLabel, img, alt, primary, dark, dark2, exLink}) => {
    const togglePage = () => {
         scroll.scrollToTop();
    }
    
    const { t } = useTranslation(); 
    

    return (
        <>
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>                    
                    <Column1>
                    <TextWrapper>
                        <TopLine lightText={lightText}>{t(topLine)}</TopLine>
                        <Heading lightText={lightText}>{t(headLine)}</Heading>
                        <Subtitle darkText={darkText}>{t(description)}</Subtitle>
                        <BtnWrap>
                            <Button 
                            to={exLink}
                            onClick={togglePage}
                            smooth={true}
                            duration={0}
                            spy={false}
                            exact="true"
                            offset={-60}
                            primary={primary ? 1 : 0}
                            dark={dark ? 1 : 0} 
                            dark2={dark2 ? 1 : 0}                            
                            >{t(buttonLabel)}</Button>
                        </BtnWrap>                                                                                              
                    </TextWrapper>                                       
                    </Column1>                    
                    <Column2>                    
                    <ImgWrap>
                        <Img src={img} alt={alt}/>
                    </ImgWrap>                                                             
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>        
        </>
    );
};

export default InfoSection;
