import styled from 'styled-components'

export const ServicesPagesContainer = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #768996;

        
    @media screen and (max-width: 780px) {
        height: 100%;        
    }

    @media screen and (max-width: 480px) {
        height: 100%;
        padding-bottom: 30px;
    }
    
    textarea {
        background-color: #9baebc;
        color: white;
        border-style: none;
        border-radius: 7px;
        margin-bottom: 30px;
    }
    
    @media screen and (max-width: 780px) {
        textarea {
        width: 90%;
        } 
    }
`

export const ServicesPagesWrapper = styled.div`
    max-width: 1200px;        
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    align-items: center;
    grid-gap: 50px;
    padding: 0 0 70px 0;
        

    @media screen  and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0 20px;                
    }

    @media screen  and (max-width: 999px) {
        grid-template-columns: 1fr; 
        padding: 0 20px;            
    }
`


export const ServicesPagesCard = styled.div`
    height: 320px;
	max-width: 300px;
	
	position: relative;
    text-align: center;
	transition: all 1.5s cubic-bezier(0.7, -0.5, 0.3, 1.8);
	transform-style: preserve-3d;    
    
    &:hover {
        transform: rotateY(180deg);
     }
`
export const ServicesPagesCardFront = styled.div `
    width: 300px;
	position: relative;
	top: 0;
	left: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
    cursor: pointer;
	height: 100%;
    background-color: #9baebc;	
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-radius: 10px;
	background-size: 90%;
    background-repeat: no-repeat;
	background-position: center center;
    filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3));
`

export const ServicesPagesCardBack = styled.div `
    width: 300px;
	position: relative;
	top: 0;
	left: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
    transform: rotateY(180deg);
	position: absolute;
	border-radius: 10px;
	height: 320px;
	background: #1b2d38;
    color: #fff;
`
export const ServicesPagesCardH2 = styled.h2 `
    padding-top: 7%;
    font-size: 14px;
	margin: 0;
	font-weight: 300;
`
export const ServicesPagesCardP = styled.p `
    padding: 7%;
    font-size: 13px;
    text-align: justify;
`

export const ServicesPagesH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin: 20px 0 30px 0;
    text-align: center;

    @media screen and (max-width: 760px) {
       font-size: 2rem;
       margin: 90px 0 30px 0;              
    }
`
export const ServicesPagesH2 = styled.h2`
    font-size: 0.9rem;
    margin-bottom: 10px;
`
export const ServicesPagesP = styled.p`
    font-size: 0.8rem;
    text-align: center;
    margin-bottom: 10px;
`

 

