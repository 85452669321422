import React from 'react'
import { FaTelegram } from 'react-icons/fa'
import { FaXTwitter } from "react-icons/fa6";
// eslint-disable-next-line
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper,
// eslint-disable-next-line    
FooterLinkItems, FooterLinkTitle, FooterLink, SocialMedia, SocialMediaWrap,
SocialLogo, WebsiteRights, SocialIcons, SocialIconLink } from './FooterElements'
import { animateScroll as scroll } from 'react-scroll'
// eslint-disable-next-line
import { useTranslation } from "react-i18next";


const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    // const { t } = useTranslation();

    return (
        <FooterContainer>
            <FooterWrap>
                {/* <FooterLinksContainer>
                    <FooterLinksWrapper>                        
                        <FooterLinkItems>
                            <FooterLinkTitle>{t("Contact Us")}</FooterLinkTitle>
                                <FooterLink to='/'>{t("Contact")}</FooterLink>
                                <FooterLink to='/'>{t("Support")}</FooterLink>                                
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>{t("About Us")}</FooterLinkTitle>
                                <FooterLink to='/'>{t("Testimonials")}</FooterLink>                                                            
                                <FooterLink to='/'>{t("Terms of Services")}</FooterLink> 
                        </FooterLinkItems>                        
                    </FooterLinksWrapper>
                </FooterLinksContainer> */}
                <SocialMedia>
                    <SocialMediaWrap>
                    <SocialLogo to='/' onClick={toggleHome}>
                    <img src={require('../../images/opdev-logo.png')} height="50px" alt="opdev_logo" />Opdev                            
                        </SocialLogo>
                        <WebsiteRights>bvox © 2022-{new Date().getFullYear()}</WebsiteRights>
                        <SocialIcons>                        
                            <SocialIconLink href="https://t.me/bvox_void" target="_blank" 
                            aria-label="Telegram">
                                <FaTelegram />
                            </SocialIconLink>                           
                            <SocialIconLink href="https://x.com/opdev_eu" target="_blank" 
                            aria-label="X">
                                <FaXTwitter />
                            </SocialIconLink>        
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>            
        </FooterContainer>
    )
}

export default Footer
