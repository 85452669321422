import React from 'react'
import { 
ServicesPagesContainer,
ServicesPagesH1,
ServicesPagesWrapper,
ServicesPagesCard,
ServicesPagesCardFront,
ServicesPagesCardBack,
ServicesPagesCardH2,
ServicesPagesCardP,
} from './ServicesPagesElements'
import { useTranslation } from "react-i18next"
import devm from '../../images/devm2.png'
import devm4 from '../../images/devm4.png'
import devm5 from '../../images/devm5.png'

const DevElements = () => { 

    const { t } = useTranslation();    

    return (
        <>
        <ServicesPagesContainer>                     
            <ServicesPagesH1>{t("Dev modules")}</ServicesPagesH1>                      
            <ServicesPagesWrapper>                            
                <ServicesPagesCard>
                    <ServicesPagesCardFront
                    style={{ backgroundImage: `url(${devm})`, backgroundSize: "80%" }}>                        
                    </ServicesPagesCardFront>
                    <ServicesPagesCardBack>
                    <ServicesPagesCardH2>{t("Data sync with DBMS")}</ServicesPagesCardH2>
                    <ServicesPagesCardP>{t("Development of modules for  of an eshop with a DBMS, warehouse accounting programs. Our modules offer real-time synchronization, ensuring that data is always up-to-date across all systems. Our solutions is designed to be scalable, allowing it to grow and adapt with your business as it expands.")}</ServicesPagesCardP>                       
                    </ServicesPagesCardBack>                   
                </ServicesPagesCard>
                <ServicesPagesCard>
                    <ServicesPagesCardFront style={{ backgroundImage: `url(${devm4})`, backgroundSize: "85%" }}/>
                    <ServicesPagesCardBack>
                    <ServicesPagesCardH2>{t("Data sync through the API")}</ServicesPagesCardH2>
                    <ServicesPagesCardP>{t("Data synchronization modules through supplier website APIs allow businesses to easily keep their product information up-to-date across multiple sales channels. By integrating with supplier APIs, businesses can automatically pull in product data such as pricing, availability, and descriptions, ensuring that their online storefronts always reflect the latest information.")}</ServicesPagesCardP>                       
                    </ServicesPagesCardBack>                   
                </ServicesPagesCard>
                <ServicesPagesCard>
                    <ServicesPagesCardFront style={{ backgroundImage: `url(${devm5})`, backgroundSize: "85%" }}/>
                    <ServicesPagesCardBack>
                    <ServicesPagesCardH2>{t("Data sync via feed")}</ServicesPagesCardH2>
                    <ServicesPagesCardP>{t("Development of data update modules through supplier feeds. Update product information on your website based on supplier information provided in the file (a file format - XML, XLS, CSV). This ensures that inventory management systems are always up-to-date, preventing overselling and underselling. It also saves time and effort in manual data entry.")}</ServicesPagesCardP>                       
                    </ServicesPagesCardBack>                   
                </ServicesPagesCard>                                             
            </ServicesPagesWrapper>           
        </ServicesPagesContainer>
        </>
    )
}

export default DevElements