import styled from 'styled-components'

export const ServicesContainer = styled.div`
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #768996;
    padding-bottom: 20px;

        
    @media screen and (max-width: 780px) {
        height: 850px;        
    }

    @media screen and (max-width: 480px) {
        height: 1150px;
        padding-bottom: 30px;                      
    }   
`

export const ServicesWrapper = styled.div`
    max-width: 1200px;    
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    align-items: center;
    grid-gap: 50px;
    padding: 0 0 70px 0;
        

    @media screen  and (max-width: 1080px) {
        grid-template-columns: 1fr 1fr; 
        padding: 0 20px;               
    }

    @media screen  and (max-width: 480px) {
        grid-template-columns: 1fr;
        grid-gap: 20px; 
        padding: 0 20px;            
    }
`
export const ServicesCard = styled.div`
    background: #9baebc;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 340px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;        
    }
`

export const ServicesCard2 = styled.div`
    height: 320px;
	max-width: 300px;
	margin: 0 auto;
	position: relative;
    text-align: center;
	transition: all 1.5s cubic-bezier(0.7, -0.5, 0.3, 1.8);
	transform-style: preserve-3d;    
    
    &:hover {
        transform: rotateY(180deg);
     }
`
export const ServicesCard2Front = styled.div `
    width: 300px;
	position: relative;
	top: 0;
	left: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
    cursor: pointer;
	height: 100%;
    background-color: #9baebc;	
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-radius: 10px;
	background-size: 90%;
    background-repeat: no-repeat;
	background-position: center center;
    filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3));
`

export const ServicesCard2Back = styled.div `
    width: 300px;
	position: relative;
	top: 0;
	left: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
    transform: rotateY(180deg);
	position: absolute;
	border-radius: 10px;
	height: 320px;
	background: #1b2d38;
    color: #fff;
`
export const ServicesCard2H2 = styled.h2 `
    padding-top: 10%;
    font-size: 16px;
	margin: 0;
	font-weight: 300;
`
export const ServicesCard2P = styled.p `
    padding: 7%;
    font-size: 13px;
    text-align: justify;
`

export const ServicesIcon = styled.img`
    /* height: 160px; */
    width: 160px;
    margin-bottom: 10px;
`

export const ServicesH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin: 50px;

    @media screen and (max-width: 480px) {
       font-size: 2rem;              
    }
`
export const ServicesH2 = styled.h2`
    font-size: 0.9rem;
    margin-bottom: 10px;
`
export const ServicesP = styled.p`
    font-size: 0.8rem;
    text-align: center;
    margin-bottom: 10px;
`

