import React from 'react';
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu,
SidebarLink } from './SidebarElements';
import { animateScroll as scroll } from 'react-scroll';
import { useTranslation } from "react-i18next";

const Sidebar = ({ isOpen, toggle }) => {
    // eslint-disable-next-line
    const togglePage = () => {
        scroll.scrollToTop();
    }

    const { t } = useTranslation();

    return (        
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle} offset={-60}>{t("About")}</SidebarLink>
                    <SidebarLink to="devmodules" onClick={toggle} offset={-60}>{t("Dev modules")}</SidebarLink>
                    <SidebarLink to="webdev" onClick={toggle} offset={-60}>{t("Web design")}</SidebarLink>                                       
                    <SidebarLink to="services" onClick={toggle} offset={-60}>{t("Services")}</SidebarLink>                    
                </SidebarMenu>                
            </SidebarWrapper>
        </SidebarContainer>        
    )
}

export default Sidebar;
