import React from 'react';
import DevElements from '../components/ServicesPages/DevComponents';
import Footer from '../components/Footer';

const DevPage = () => { 
    
    return (
        <>
         <DevElements />
         <Footer />                            
        </>
    );
};

export default DevPage;