import React from 'react'
import { 
ServicesPagesContainer,
ServicesPagesH1,
ServicesPagesWrapper,
ServicesPagesCard,
ServicesPagesCardFront,
ServicesPagesCardBack,
ServicesPagesCardH2,
ServicesPagesCardP,
} from './ServicesPagesElements'
import { useTranslation } from "react-i18next"
import oc from '../../images/eshop3.png'
import ps from '../../images/ps.png'
import delivery from '../../images/delivery.png'

const AboutUsElements = () => { 

    const { t } = useTranslation();    

    return (
        <>
        <ServicesPagesContainer>                     
            <ServicesPagesH1>{t("Our services")}</ServicesPagesH1>                      
            <ServicesPagesWrapper>                            
                <ServicesPagesCard>
                    <ServicesPagesCardFront
                    style={{ backgroundImage: `url(${oc})`, backgroundSize: "90%" }}>                        
                    </ServicesPagesCardFront>
                    <ServicesPagesCardBack>
                    <ServicesPagesCardH2>{t("Online shops")}</ServicesPagesCardH2>
                    <ServicesPagesCardP>{t("We specializes in creating ecommerce online stores on OpenCart. With OpenCart, we can build powerful and customized online stores that cater to the specific needs of our clients. We provide a range of services, including store design, theme selection, installation, optimization for search engines, and customization of features to meet your business requirements.")}</ServicesPagesCardP>                       
                    </ServicesPagesCardBack>                   
                </ServicesPagesCard>
                <ServicesPagesCard>
                    <ServicesPagesCardFront style={{ backgroundImage: `url(${ps})`, backgroundSize: "90%" }}/>
                    <ServicesPagesCardBack>
                    <ServicesPagesCardH2>{t("Payment systems")}</ServicesPagesCardH2>
                    <ServicesPagesCardP>{t("We specializes in providing seamless integration and connection with various payment systems for your ecommerce online store. We understand the importance of secure, reliable, and easy-to-use payment systems for your customers, which is why we offer integration with multiple payment gateways, including PayPal, Stripe, and many others.")}</ServicesPagesCardP>                       
                    </ServicesPagesCardBack>                   
                </ServicesPagesCard>
                <ServicesPagesCard>
                    <ServicesPagesCardFront style={{ backgroundImage: `url(${delivery})`, backgroundSize: "80%" }}/>
                    <ServicesPagesCardBack>
                    <ServicesPagesCardH2>{t("Delivery services")}</ServicesPagesCardH2>
                    <ServicesPagesCardP>{t("By integrating your online store with reputable delivery services, we can help you provide your customers with a range of shipping options that cater to their needs and preferences. We are help you create a hassle-free experience for your customers while also reducing the workload for your team. We take care of the technical aspects of the integration, allowing you to focus on growing your business.")}</ServicesPagesCardP>                       
                    </ServicesPagesCardBack>                   
                </ServicesPagesCard>        
            </ServicesPagesWrapper>           
        </ServicesPagesContainer>
        </>
    )
}

export default AboutUsElements