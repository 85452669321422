import React from 'react'
import { ServicesContainer, ServicesH1, ServicesWrapper,
ServicesCard2,
ServicesCard2Front,
ServicesCard2Back,
ServicesCard2H2,
ServicesCard2P,
} from './ServicesElements'
import { useTranslation } from "react-i18next"

const Services = () => {     

    const { t } = useTranslation();

    return (
        <ServicesContainer id="services"> 
            <ServicesH1>{t("Our services")}</ServicesH1>            
            <ServicesWrapper>                
                <ServicesCard2>
                    <ServicesCard2Front style={{ backgroundImage: "url(/eshop3.webp)", backgroundSize: "85%" }}/>
                    <ServicesCard2Back>
                    <ServicesCard2H2>{t("Online shops")}</ServicesCard2H2>
                    <ServicesCard2P>{t("Development online shops based on CMS OpenCart, integration of modules, parsing, import-export contents, data synchronization with trading platforms and services, payment systems, delivery services, etc. We provide full cycle of services for creating and maintaining online shops: from design to technical support.")}</ServicesCard2P>                       
                    </ServicesCard2Back>                   
                </ServicesCard2>
                <ServicesCard2>
                    <ServicesCard2Front style={{ backgroundImage: "url(/dev5.webp)", backgroundSize: "85%" }}/>
                    <ServicesCard2Back>
                    <ServicesCard2H2>{t("Dev modules")}</ServicesCard2H2>
                    <ServicesCard2P>{t("Development data synchronization modules about products and site categories with DBMS. Loaders products from files in XLS, CSV, XML format to the online stores database. Data loaders using the suppliers API to the site database. Automation of the product data synchronization process.")}</ServicesCard2P>                       
                    </ServicesCard2Back>                   
                </ServicesCard2>
                <ServicesCard2>
                    <ServicesCard2Front style={{ backgroundImage: "url(/webd3.webp)", backgroundSize: "85%" }}/>
                    <ServicesCard2Back>
                    <ServicesCard2H2>{t("Web dev")}</ServicesCard2H2>
                    <ServicesCard2P>{t("Developing high-quality websites and online stores using cutting-edge technologies such as Node.js, React/Next.js and MERN stack. Whether you are looking to build a simple website or a complex online store, we are committed to delivering exceptional results that exceed your expectations.")}</ServicesCard2P>                       
                    </ServicesCard2Back>                   
                </ServicesCard2>                
            </ServicesWrapper>           
        </ServicesContainer>
    )
}

export default Services
