import React from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import AboutUsPage from "./pages/about-us";
import DevPage from "./pages/dev";
import WebPage from "./pages/webdesign";

function App() {
  
  return (
   <Router>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/dev" element={<DevPage />} />
      <Route path="/webdev" element={<WebPage />} />            
      </Routes>      
    </Router>
  );
}

export default App;
