import React from 'react';
import { FaBars } from 'react-icons/fa';
// eslint-disable-next-line
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLngSwitcher, NavLinks, NavLinks2, Banner } from './NavbarElements';
import { animateScroll as scroll } from 'react-scroll';
import LanguageSwitcher from "../LanguageSwitcher";
import { useTranslation } from "react-i18next";


const Navbar = ({ toggle }) => {
    const { t } = useTranslation();

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
       <>    
        {/* <Banner href="https://bank.gov.ua/en/news/all/natsionalniy-bank-vidkriv-spetsrahunok-dlya-zboru-koshtiv-na-potrebi-armiyi" target="_blank" 
        title="Do something to stop this war! Russians are killing our children and civilians! Русский военный корабль - иди нах*й!!!">{null}
                </Banner> */}
       <Nav>
           <NavbarContainer>           
               <NavLogo to='/' onClick={toggleHome}>
               <img src={require('../../images/opdev-logo.png')} height="50px" alt="opdev_logo" />opdev
              </NavLogo>              
               <MobileIcon onClick={toggle}>
                   <FaBars />
               </MobileIcon>               
               <NavMenu>
                   <NavItem>
                       <NavLinks to='about'
                           smooth={true} duration={500} spy={true}
                           exact='true' offset={-64}                           
                           >{t("About")}</NavLinks>
                   </NavItem>
                   <NavItem>
                       <NavLinks to='devmodules'
                       smooth={true} duration={500} spy={true}
                       exact='true' offset={-64}
                       >{t("Dev modules")}</NavLinks>
                   </NavItem>
                   <NavItem>
                       <NavLinks to='webdev'
                       smooth={true} duration={500} spy={true}
                       exact='true' offset={-64}
                       >{t("Web dev")}</NavLinks>
                   </NavItem>
                   
                   <NavItem>
                       <NavLinks to='services'
                    // onClick={toggleHome}
                       smooth={true} duration={500} spy={true}
                       exact='true' offset={-64}
                       >{t("Services")}</NavLinks>
                   </NavItem>
               </NavMenu>
               <NavLngSwitcher>
                    <LanguageSwitcher />
                </NavLngSwitcher>               
               {/* <NavBtn>
                   <NavBtnLink to="/signin">Sign In</NavBtnLink>
               </NavBtn> */}
           </NavbarContainer>
       </Nav>
       </>
    )
}

export default Navbar
